<template>
  <form @submit.prevent="update" >
    <div class="modal-body modal-open modal-height-max modal-row2 " style="max-height: 66vh" v-if="profile">
      <div class="row gutters mb-2">
        <div style="padding-left: 40%">
          <div class="avatar-size avatar" style="margin-left: 30px !important;" v-if="!widthPhone()">
            <img class="user-avatar" :src="imageCropped ? imageCropped : profile.avatar">
          </div>
        </div>
      </div>

      <div class="gutters">
        <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 text-center" >
          <label>{{ t('GENERAL.CURRENT_PASSWORD') }}: </label>
          <div class="form-group">
            <input data-cy="change_password_current" class="col-md-3 form-control" style="margin: 0 auto"  type="password" autocomplete="off" name="currentPassword" id="currentPassword" placeholder="Insira a senha atual" v-model="this.currentPassword">
          </div>
        </div>
        <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
          <label>{{ t('GENERAL.NEW_PASSWORD') }}: </label>
          <div class="form-group">
            <input data-cy="login_creat_password_confirm" class="col-md-3 form-control" style="margin: 0 auto" type="password" autocomplete="off"
                   id="password" v-model="newPassword" name="password"
                   placeholder="Insira nova senha" @keyup="inputPassword(this.newPassword, this.passwordConfirm)" />
          </div>
        </div>
        <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
          <label>{{ t('GENERAL.CONFIRM_NEW_PASSWORD') }}: </label>
          <div class="form-group">
            <input data-cy="login_creat_password_confirm" class="col-md-3 form-control" style="margin: 0 auto" type="password" id="passwordConfirm" autocomplete="off"
                   v-model="passwordConfirm" name="passwordConfirm" placeholder="Confirmar senha" @keyup="inputPassword(this.newPassword, this.passwordConfirm)"/>
            <div class="validation" id="invalidPassword" style="display: none">{{msgPassword}}</div>
          </div>
          <small id="passwordHelpInline" class="text-muted">
            {{ t('GENERAL.MSG.MAX_MIN_PASSWORD') }}
          </small>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-right">
      <button data-cy="profile_update_modal_close" id="closeModal" class="btn btn-secondary" data-dismiss="modal" type="button" @click="closeFirstModal()">{{ t('ACTIONS.CLOSE') }}</button>
      <button data-cy="profile_update_modal_confirm" class="btn btn-primary" type="submit">{{ t('ACTIONS.CONFIRM') }}</button>
    </div>
  </form>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import User from '@/services/user';
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';

export default {
  name: 'passwordModal',
  props: ['profileIndex'],
  mixins: [Validate],


  setup() {
    const {t} = useI18n();
    const toast = useToast();
    return {t, toast}
  },

  computed: {
    ...mapState({
      imageCropped: 'imageCropped',
    }),
  },

  data() {
    return {
      profile: null,
      currentPassword: null,
      newPassword: null,
      passwordConfirm : null,
    }
  },

  mounted() {
    this.profile = this.profileIndex;
  },

  watch: {
    profileIndex: function() {
      this.profile = this.profileIndex;
    }
  },

  methods: {
    update() {
      this.invalid = [];
      this.passwordValidade(this.newPassword, this.passwordConfirm);

      if (this.invalid.length == 0) {
        this.isLoading = true;
        this.startForm();

        User.resetPasswordLogged(this.form).then(res => {
          this.isLoading = false;
          this.cleanField();
          document.getElementById('closeModal').click();
          this.toast.success(res.data.message);

        }).catch(() => {
          this.isLoading = false;
          this.cleanField();
          this.toast.error(this.t('GENERAL.MSG.PASSWORD_DIFFERENT_FROM_THE_CURRENT'), {timeout: false});
          return false;
        });
      }
    },

    startForm() {
      this.form = {
        email: this.profile.email,
        currentPassword: this.currentPassword,
        password: this.newPassword,
      }
    },

    cleanField(){
      document.getElementById('currentPassword').value = '';
      document.getElementById('password').value = '';
      document.getElementById('passwordConfirm').value = '';
    },

    inputPassword(password, passwordConfirm, isSingup = true) {
      document.getElementById("invalidPassword").style.color = "red";
      document.getElementById("password").classList.remove("is-valid");
      document.getElementById("invalidPassword").style.display = "none";
      document.getElementById("password").classList.remove("is-invalid");
      document.getElementById("passwordConfirm").classList.remove("is-valid");
      document.getElementById("passwordConfirm").classList.remove("is-invalid");
      this.passwordValidade(password, passwordConfirm, isSingup);
       if (this.passwordConfirm){
          this.passwordConfirm = this.passwordConfirm.trim();
       }
       if (this.newPassword){
          this.newPassword = this.newPassword.trim();
       }
    },

    passwordValidade(password, passwordConfirm, isSingup = true) {
      if (!password) {
        this.invalid.push(true);
        this.msgPassword = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById("password").classList.add("is-invalid");
        document.getElementById("invalidPassword").style.display = "block";
      }

      if (isSingup && !passwordConfirm) {
        this.invalid.push(true);
        this.msgPassword = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById("invalidPassword").style.display = "block";
        document.getElementById("passwordConfirm").classList.add("is-invalid");
      }

      this.validateEqualityPassword(password, passwordConfirm);
    },

    validateEqualityPassword(password, passwordConfirm) {
      if (password != passwordConfirm) {
        this.invalid.push(true);
        this.msgPassword = this.t("GENERAL.MSG.DIFFERENT_PASSWORDS");
        document.getElementById("password").classList.add("is-invalid");
        document.getElementById("invalidPassword").style.display = "block";
        document.getElementById("passwordConfirm").classList.add("is-invalid");
      } else {
        this.passwordStrength(password);

        if (this.invalid.length == 0) {
          document.getElementById("password").classList.add("is-valid");
          document.getElementById("passwordConfirm").classList.add("is-valid");
        }
      }
    },

    passwordStrength(password) {
      var numbers = /([0-9])/;
      var alphabet = /([a-zA-Z])/;
      var spCharacter = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

      if (password) {
        if (password.length < 8) {
          this.invalid.push(true);
          this.msgPassword = this.t("GENERAL.MSG.PASSWORD_TO_SMALL");
          document.getElementById("password").classList.add("is-invalid");
          document.getElementById("invalidPassword").style.display = "block";
          document
              .getElementById("passwordConfirm")
              .classList.add("is-invalid");
        } else if (password.length > 20) {
          this.invalid.push(true);
          this.msgPassword = this.t("GENERAL.MSG.PASSWORD_TO_LONG");
          document.getElementById("password").classList.add("is-invalid");
          document.getElementById("invalidPassword").style.display = "block";
          document
              .getElementById("passwordConfirm")
              .classList.add("is-invalid");
        } else {
          if (
              password.match(numbers) &&
              password.match(alphabet) &&
              password.match(spCharacter)
          ) {
            this.msgPassword = this.t("GENERAL.MSG.PASSWORD_STRONG");
            document.getElementById("invalidPassword").style.color = "green";
            document.getElementById("invalidPassword").style.display = "block";
          } else if (
              password.match(alphabet) &&
              password.match(numbers) &&
              !password.match(spCharacter)
          ) {
            this.msgPassword = this.t("GENERAL.MSG.PASSWORD_MEDIUM");
            document.getElementById("invalidPassword").style.color = "orange";
            document.getElementById("invalidPassword").style.display = "block";
          } else if (
              (password.match(alphabet) &&
                  !password.match(numbers) &&
                  !password.match(spCharacter)) ||
              (!password.match(alphabet) &&
                  password.match(numbers) &&
                  !password.match(spCharacter))
          ) {
            this.invalid.push(true);
            this.msgPassword = this.t("GENERAL.MSG.PASSWORD_WEAK");
            document.getElementById("password").classList.add("is-invalid");
            document.getElementById("invalidPassword").style.display = "block";
            document
                .getElementById("passwordConfirm")
                .classList.add("is-invalid");
          }
        }
      }
    },

    checkForm() {
      this.nameValidate(this.profile.name);

      if (!this.profile.phones[0]) {
        this.toast.error(this.t('GENERAL.MSG.ADD_ONE_PHONE'), { timeout: false })
        return false;
      }

      if (this.invalid.length && this.invalid.length > 0) {
        return false;
      }
      return true;
    },

    uploadImage() {
      const file = document.querySelector('input[type=file]').files[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        this.isCropping = true;
        this.$store.commit('setImage', reader.result);
        document.getElementById('imageCropper').click();
      }

      reader.readAsDataURL(file);
    },

    updatePhone(phones) {
      this.profile.phones = phones;
    },

    closeFirstModal() {
      if (localStorage.getItem('firstProfileClosed') == "true") {
        this.$emit('update');
        localStorage.setItem('firstProfileClosed', false);
      }
    }
  }
}
</script>
