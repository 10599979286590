<template>
   <form @submit.prevent="update">
      <div class="modal-body modal-open modal-height-max modal-row2" style="max-height: 66vh" v-if="profile">
         <div class="row gutters mb-2">
            <div style="padding-left: 40%">
               <div class="avatar-size avatar" v-if="widthPhone()">
                  <label for='avatar' class="pointer"><img class="user-avatar"
                                                           :src="imageCropped ? imageCropped : profile.avatar"></label>
                  <input type="file" id="avatar" accept="image/jpeg/*" @change="uploadImage()" style=" display: none;">
                  <div class="status pointer">
                     <label for='avatar'><i class="icon-camera camera"/></label>
                     <input type="file" id="avatar" accept="image/jpeg/*" @change="uploadImage()"
                            style=" display: none;">
                  </div>
               </div>
               <div class="avatar-size avatar" style="margin-left: 30px !important;" v-if="!widthPhone()">
                  <img class="user-avatar" :src="imageCropped ? imageCropped : profile.avatar">
                  <div class="status pointer">
                     <label for='avatar'><i class="icon-camera camera"/></label>
                     <input type="file" id="avatar" accept="image/jpeg/*" @change="uploadImage()"
                            style=" display: none;">
                  </div>
               </div>
            </div>
         </div>

         <div class="row gutters">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
               <div class="form-group">
                  <label for="name" class="required">{{ t('GENERAL.NAME') }}: </label>
                  <input data-cy="profile_update_modal_name" class="form-control" type="text" name="name" id="name"
                         placeholder="Digite o nome" v-model="profile.name" @keyup="inputName(profile.name)">
                  <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
               <div class="form-group">
                  <label for="email">{{ t('GENERAL.EMAIL') }}: </label>
                  <input data-cy="profile_update_modal_email" class="form-control" type="text" name="email" id="email"
                         v-model="profile.email" disabled>
                  <div v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}</div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
               <div class="form-group">
                  <label for="taxpayer" class="required">{{ t('GENERAL.TAXPAYER') }}: </label>
                  <input data-cy="profile_update_modal_email" class="form-control" type="text" name="email"
                         id="taxpayer" v-model="profile.taxpayer"
                         v-mask="['###.###.###-##', '##.###.###/####-##']"
                  >
                  <div v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}</div>
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
               <div class="form-group">
                  <label for="creci">{{ t('GENERAL.CRECI') }}: </label>
                  <input data-cy="profile_update_modal_email" class="form-control" type="text" name="email" id="creci"
                         v-model="profile.creci">
                  <div v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}</div>
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
               <div class="form-group">
                  <label
                     for="birthdate">{{
                        (profile?.taxpayer?.length > 14) ? t('COMPANIES.BIRTHDATE_COMAPANY') : t('COMPANIES.BIRTHDATE')
                     }}:</label>
                  <input type="date" id="birthdate" name="birthdate" v-model="profile.birthday" class="form-control">
               </div>
            </div>
         </div>
         <div class="row gutters">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
               <div class="form-group">
                  <phone v-if="profile.phones" :phonesIndex="profile.phones" :required="true"
                         @update="updatePhone($event)"></phone>
               </div>
            </div>
         </div>
         <div class="row gutters">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-2">
               <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="active" v-model="profile.accept_marketing">
                  <label data-cy="profile_update_about_r2" class="custom-control-label"
                         for="active">{{ t('USERS.ACCEPT_RECEIVE_NEWS_ABOUT_R2') }}</label>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer justify-content-right">
         <button data-cy="profile_update_modal_close" class="btn btn-secondary" data-dismiss="modal" type="button"
                 @click="closeFirstModal()">{{ t('ACTIONS.CLOSE') }}
         </button>
         <button data-cy="profile_update_modal_confirm" class="btn btn-primary" type="submit">{{
               t('ACTIONS.CONFIRM')
            }}
         </button>
      </div>
   </form>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import User from '@/services/user';
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import phone from '@/components/layouts/phoneComponent.vue';

export default {
   name: 'profileModal',
   props: ['profileIndex'],
   mixins: [Validate],
   components: {
      phone,
   },

   setup() {
      const {t} = useI18n();
      const toast = useToast();
      return {t, toast}
   },

   computed: {
      ...mapState({
         imageCropped: 'imageCropped',
      }),
   },

   data() {
      return {
         profile: null,
      }
   },

   mounted() {
      this.profile = this.profileIndex;
   },

   watch: {
      profileIndex: function () {
         this.profile = this.profileIndex;
      }
   },

   methods: {
      update() {
         this.invalid = [];
          if (!this.profile?.taxpayer){
              this.toast.error('CPF/CNPJ obrigatorio')
          }
         if (this.checkForm()) {
             if (!this.profile?.taxpayer){
                 return
             }
            this.$store.commit('changeLoading', true);

            if (this.imageCropped) {
               this.profile.avatar = this.imageCropped;
            }

            User.update(this.profile).then(() => {
               this.$store.dispatch('ActionRefreshToken');
               document.getElementById('closeProfile').click();
               this.toast.success(this.t('GENERAL.MSG.UPDATE_SUCCESS'));

               this.$emit('update');

               this.$store.commit('changeLoading', false);
            }).catch(error => {
               this.$store.commit('changeLoading', false);
               this.errorMsg(error);
            });
         }
      },

      checkForm() {
         this.nameValidate(this.profile.name);

         if (!this.profile.phones[0]) {
            this.toast.error(this.t('GENERAL.MSG.ADD_ONE_PHONE'), {timeout: false})
            return false;
         }

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         return true;
      },

      uploadImage() {
         const file = document.querySelector('input[type=file]').files[0]
         const reader = new FileReader()

         reader.onloadend = () => {
            this.isCropping = true;
            this.$store.commit('setImage', reader.result);
            document.getElementById('imageCropper').click();
         }

         reader.readAsDataURL(file);
      },

      updatePhone(phones) {
         this.profile.phones = phones;
      },

      closeFirstModal() {
         if (localStorage.getItem('firstProfileClosed') == "true") {
            this.$emit('update');
            localStorage.setItem('firstProfileClosed', false);
         }
      }
   }
}
</script>
