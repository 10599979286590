<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header modal-header-color">
                <h5 class="modal-title" id="footerCenterBtnsModalLabel">{{ t('APP.MODAL.SELECT') }}</h5>
              </div>
              <div class="modal-body modal-open modal-height-max">
                <div class="row gutters">
                  <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" name="company" v-model="companyModal" id="company"
                             class="form-control mb-2" @keyup="selectCompanyModal"
                             placeholder="Digite o nome da companhia para buscar">
                      <div class="justify-content-center modal-row2">
                        <div v-for="company in companies" :key="company.id">
                          <button class="btn btn-background mt-1" @click="companyModalSelected(company.id)">
                            {{ company.text }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import {mapState, mapGetters} from "vuex";

export default {
  name: "modalCompany",
  props: {
    companiesBackup: {
      type: Object
    },
  },

  setup() {
    const {t} = useI18n();
    const toast = useToast();
    return {t, toast}
  },

  computed: {
    ...mapState({
      companies: 'companies'
    }),
    ...mapGetters(['sessionStatus']),
  },

  data() {
    return {
      companyModal: null,
      companies: null,
    }
  },

  mounted() {
    this.companies = this.companiesBackup;
  },

  methods: {
    selectCompanyModal() {
      this.companiesAux = [];
      this.companies = this.companiesBackup;
      this.companies.forEach(company => {

        const text = company.text.toUpperCase();
        const textModal = this.companyModal.toUpperCase();

        if (text.includes(textModal)) {
          this.companiesAux.push(company);
        }
      })
      this.companies = this.companiesAux;
    },

    companyModalSelected(companyIdModal) {
      this.companyId = companyIdModal;
      localStorage.removeItem('companyId');
      this.companySelected = companyIdModal;
      this.$store.commit('showModalSelectCompany', false);
      localStorage.setItem('companyId', companyIdModal);
      this.$router.go();
    },
  }
}
</script>
