<template>
   <div>
      <div class="modal fade" id="modalCropper" role="dialog" data-backdrop="static"
           aria-labelledby="staticBackdropLabel" aria-hidden="true" style="position: fixed; z-index: 10000;">
         <div class="modal-dialog" role="document">
            <div class="modal-content">

               <div class="modal-header hearder-custom border-bottom">
                  <h4 class="modal-title">{{ t('USERS.IMAGE') }}</h4>
                  <button type="button" class="close close-custom" id="closeCropper" data-dismiss="modal"
                          aria-label="Close" @click="close()">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>

               <div class="modal-body hearder-custom">
                  <div class="row gutters custom-margin">
                     <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                     <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11">
                        <div class="dimensions">altura e largura: Max: 380 x 380px - Min: 150 x 150px</div>
                        <div class="cropper-size">
                           <cropper
                               ref="cropper"
                               class="twitter-cropper"
                               background-class="twitter-cropper__background"
                               foreground-class="twitter-cropper__foreground"
                               image-restriction="stencil"
                               :stencil-size="{width: 380, height: 380}"
                               :stencil-props="{
                      lines: {},
                      handlers: {},
                      movable: false,
                      scalable: false,
                      aspectRatio: 1,
                      previewClass: 'twitter-cropper__stencil',
                    }"
                               :transitions="false"
                               :canvas="true"
                               :debounce="false"
                               :default-size="defaultSize"
                               :min-width="0"
                               :min-height="0"
                               :max-width="800"
                               :max-height="800"
                               :src="image"
                               @change="onChange"
                           />
                           <navigation :zoom="zoom" @change="onZoom"/>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal-footer right border-top">
                  <button type="button" class="btn btn-secondary mr-1 width-buttons" data-dismiss="modal"
                          @click="close()">
                     {{ t('ACTIONS.CLOSE') }}
                  </button>
                  <button type="button" data-cy="cropper_confirm_image" class="btn btn-primary mr-1 width-buttons"
                          data-dismiss="modal" @click="confirmCropper()">
                     {{ t('ACTIONS.CONFIRM') }}
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from "vue-i18n";
import Navigation from "./navigation";
import {useToast} from "vue-toastification";
import "vue-advanced-cropper/dist/style.css";
import {Cropper} from 'vue-advanced-cropper';

export default {
   name: 'CropperImg',
   components: {
      Cropper,
      Navigation,
   },

   setup() {
      const {t} = useI18n();
      const toast = useToast();
      return {t, toast}
   },

   computed: {
      ...mapState({
         image: 'image',
      }),
   },

   data() {
      return {
         zoom: 0,
         avatar: null,
      };
   },

   methods: {
      onChange(result) {
         const cropper = this.$refs.cropper;
         if (cropper) {
            const {coordinates, imageSize} = cropper;
            this.avatar = result.canvas.toDataURL();
            if (
                imageSize.width / imageSize.height >
                coordinates.width / coordinates.height
            ) {
               if (cropper.imageSize.height < 150){
                  this.zoom = 100;
                  return;
               }
               this.zoom =
                   (cropper.imageSize.height - cropper.coordinates.height) /
                   (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
            } else {
               if (cropper.imageSize.width < 150){
                  this.zoom = 100;
                  return;
               }
               this.zoom =
                   (cropper.imageSize.width - cropper.coordinates.width) /
                   (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
            }
         }
      },

      defaultSize() {
         return {
            width: 800,
            height: 800,
         };
      },
      stencilSize({boundaries}) {
         return {
            width: Math.min(boundaries.height, boundaries.width) - 48,
            height: Math.min(boundaries.height, boundaries.width) - 48,
         };
      },

      onZoom(value) {
         const cropper = this.$refs.cropper;
         if (cropper) {
            if (cropper.imageSize.height < cropper.imageSize.width) {
               const minHeight = cropper.sizeRestrictions.minHeight;
               const imageHeight = cropper.imageSize.height;
               cropper.zoom(
                   (imageHeight - this.zoom * (imageHeight - minHeight)) /
                   (imageHeight - value * (imageHeight - minHeight))
               );
            } else {
               const minWidth = cropper.sizeRestrictions.minWidth;
               const imageWidth = cropper.imageSize.width;
               cropper.zoom(
                   (imageWidth - this.zoom * (imageWidth - minWidth)) /
                   (imageWidth - value * (imageWidth - minWidth))
               );
            }
         }
      },

      confirmCropper() {
         this.$store.commit('setImageCropped', this.avatar);
      },

      close() {
         this.$emit('cropperUpdate');
         this.$store.commit('setImageCropped', null);
      }
   }
}
</script>
<style lang="scss">
.twitter-cropper {
   height: 250px;

   &__background {
      background-color: #edf2f4;
   }

   &__foreground {
      background-color: #edf2f4;
   }

   &__stencil {
      border: solid 5px rgb(29, 161, 242);
   }
}

.dimensions {
   color: #5f5f5f;
   font-size: 10px;
}
</style>
